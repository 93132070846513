import * as React from "react";
//import App.css
import styles from "./App.css";
// 1. import `NextUIProvider` component
import { AnimatePresence, useMotionValue } from "framer-motion";
import { Card, CardFooter, Image, Button } from "@nextui-org/react";
import { motion, useViewportScroll, useTransform } from "framer-motion";
import { Tooltip,Popover, PopoverTrigger, PopoverContent } from "@nextui-org/react";
import Carousel from "nuka-carousel"
import Typewriter from "typewriter-effect";


const DEProjects = [

  "Random Number Generator",
  "Hand Held Fan Project"
]

const conversation = {
  intro: {
    message: "Hey there! Ask me any question!",
    replyOptions: [
      ["What school do you go to?", "school"],
      ["What are some of your accomplishments?", "accomplishments"],
      ["What experience do you have?", "experience"],
      ["What are your hobbies?", "hobbies"],
      ["Go Back", "backMenu"],
    ],
  },
  school: {
    message: "I am currently attending Glen Elg Country School",
    replyOptions: [
      ["What are some of your accomplishments?", "accomplishments"],
      ["What experience do you have?", "experience"],
      ["Go Back", "back"],
    ],
  },
  accomplishments: {
    message: "I was awarded first place in 2023 MIST",
    replyOptions: [
      ["What school do you go to?", "school"],
      ["What experience do you have?", "experience"],
      ["Go Back", "back"],
    ],
  },
  experience: {
    message:
      "I have experience in HTML, CSS, Javascript, Java, Python, Lua, and a bit of C++ and C#",
    replyOptions: [
      ["What projects have you worked on?", "projects"],
      ["What do you do in Web Development?", "web"],
      ["What have you done in Javascript?", "javascript"],
      ["What experience do you have in Python?", "python"],
      ["What are your accomplishments in Lua?", "lua"],
      ["Go Back", "back"],
    ],
  },
  projects: {
    message:
      "I have worked on a few projects, such as this website, a Discord bot, and a few games",
    replyOptions: [
      ["What school do you go to?", "school"],
      ["What experience do you have?", "experience"],
      ["Go Back", "back"],
    ],
  },
  web: {
    message:
      "I have worked on a few websites, such as this one, and a few others",
    replyOptions: [
      ["What school do you go to?", "school"],
      ["What experience do you have?", "experience"],
      ["Go Back", "back"],
    ],
  },
  javascript: {
    message:
      "I have made a lot of backends for chat apps, as well as QR code scanners",
    replyOptions: [
      ["What school do you go to?", "school"],
      ["What experience do you have?", "experience"],
      ["Go Back", "back"],
    ],
  },
  python: {
    message: "I have made a few games and a few chat apps",
    replyOptions: [
      ["What school do you go to?", "school"],
      ["What experience do you have?", "experience"],
      ["Go Back", "back"],
    ],
  },
  lua: {
    message: "I have made games on engines such as Roblox",
    replyOptions: [
      ["What school do you go to?", "school"],
      ["What experience do you have?", "experience"],
      ["Go Back", "back"],
    ],
  },
  hobbies: {
    message: "I like to code, play video games, and play sports",
    replyOptions: [
      ["What school do you go to?", "school"],
      ["What experience do you have?", "experience"],
      ["Go Back", "back"],
    ],
  },
};
const stylesMsg = {
  body: {
    fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
    fontSize: "2vh",
    fontWeight: "normal",
    overflow: "auto",
    padding: "2vw",
    display: "flex",
    width: "100vw",
    height: "100vh !important",
    flexDirection: "column",
    backgroundColor: "#0e0e0e",
  },
  p: {
    maxWidth: "55vw",
    wordWrap: "break-word",
    marginBottom: 12,
    animation: "popIn 0.3s",
    
    lineHeight: 2,
    position: "relative",
    padding: "10px 20px",
    borderRadius: 2500,
    "&::before": {
      content: "",
      position: "absolute",
      bottom: 0,
      height: "25px",
    },
    "&::after": {
      content: "",
      position: "absolute",
      bottom: 0,
      height: "25px",
    },
  },
  send: {
    color: "white",
    background: "#0B93F6",
    alignSelf: "flex-end",
    marginRight: "3vw",
  },
  receive: {
    background: "#323332",
    color: "white",
    alignSelf: "flex-start",
    marginLeft: "3vw",
    "&::before": {
      content: `''`,
      left: "-7px",
      width: "20px",
      backgroundColor: "var(--receive-bg)",
      borderBottomRightRadius: 16,
    },

    "&::after": {
      content: `''`,
      left: "-26px",
      width: "26px",
      backgroundColor: "var(--page-background)",
      borderBottomRightRadius: 10,
    },
  },

  optionMsg: {
    color: "white",
    alignSelf: "flex-end",
    marginRight: "3vw",
    border: "1px solid",
    borderColor: "white",
    "&::before": {
      content: `''`,
      left: "-7px",
      width: "20px",
      backgroundColor: "var(--receive-bg)",
      borderBottomRightRadius: 16,
    },

    "&::after": {
      content: `''`,
      left: "-26px",
      width: "26px",
      backgroundColor: "var(--page-background)",
      borderBottomRightRadius: 10,
    },
  },
};
const FirstPage = ({ setIntroAnim }) => {
  return (
    <div className="page-container">
      <div className="page-container1">
        <div className="page-testimonial">
          <motion.span
            initial={{ opacity: 0 }}
            exit={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 2.5, type: "spring", delay: 3 }}
            data-thq-animate-on-reveal="true"
            className="page-text"
          >
            <Typewriter
              onInit={(typewriter) => {
                typewriter
                  .pauseFor(2.7 * 500)
                  .typeString("Hey!")
                  .pauseFor(500)
                  .typeString(" I'm ThatOneDev")
                  .pauseFor(1000)
                  .deleteChars(10)
                  .pauseFor(500)
                  .typeString("Yusuf Masood")
                  .pauseFor(500)
            
                  .pauseFor(750)
                  .callFunction(() => {
                    setIntroAnim(true);
                  })

                  //  .callFunction(() => {
                  //  console.log('All strings were deleted');
                  //  })
                  .start();
              }}
            />
          </motion.span>

          <motion.div
            initial={{ y: -800, opacity: 0 }}
            exit={{ y: -800, opacity: 0 }}
            key="modal3"
            animate={{ y: 0, opacity: 1 }}
            transition={{ duration: 2.5, type: "spring" }}
          >
            <h1 className="page-text5">
              <span> </span>
              <br></br>
            </h1>
          </motion.div>
        </div>
      </div>
    </div>
  );
};



const FeatureCard4 = (props) => {
  return (
    <div  className={`feature-card4-feature-card ${props.rootClassName} ${props.className} `}>
      <img className="feature-card4-icon" src={props.src} alt="Icon" />
      <h2 className="feature-card4-text">{props.title}</h2>
      <span className="feature-card4-text1">{props.description}</span>
    </div>
  )
}
const References = (props) => {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      slidesToSlide: 3 // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 2 // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1 // optional, default to 1.
    }
  };
  return (
    <div style={{backgroundColor: "#0e0e0e", textAlign:"center"}}>
        <span className="references-text">
          <span>References</span>
          <br></br>
        </span>
 <Carousel autoplay={false}  autoplayInterval={2000} wrapAround={true} style={{height:"90vh"}}>
 <div className="references-container">
      <div className="references-container1">
      
        <div className="references-testimonial-card">
          <div className="references-testimonial">
            <span className="references-text3">
              &quot;In 29 years of teaching, Yusuf is only the 2nd freshman ever to take our AP CS course at GCS.  He is a dedicated student of computer science and fascinated with expanding his understanding of emerging technologies.&quot;
            </span>
            <span className="references-text4">Marc Schmidt</span>
            <span className="references-text5">
              <span>Director of Academic Technology @ Glen Elg Country School</span>
              <br></br>
            </span>
          </div>
          <img
            alt="profile"
            src="/marc.jpg"
            className="references-image"
          />
        </div>
      </div>
    </div>
    {/* 
    <div className="references-container">
      <div className="references-container1">
      
        <div className="references-testimonial-card">
          <div className="references-testimonial">
            <span className="references-text3">
              &quot;Quote&quot;
            </span>
            <span className="references-text4">Person</span>
            <span className="references-text5">
              <span>Position</span>
              <br></br>
            </span>
          </div>
          <img
            alt="profile"
            src="https://play.teleporthq.io/static/svg/default-img.svg"
            className="references-image"
          />
        </div>
      </div>
    </div>
    <div className="references-container">
      <div className="references-container1">
      
        <div className="references-testimonial-card">
          <div className="references-testimonial">
            <span className="references-text3">
              &quot;quote&quot;
            </span>
            <span className="references-text4">name</span>
            <span className="references-text5">
              <span>position</span>
              <br></br>
            </span>
          </div>
          <img
            alt="profile"
            src="https://play.teleporthq.io/static/svg/default-img.svg"
            className="references-image"
          />
        </div>
      </div>
    </div>
    */}
    
  </Carousel>
    </div>
   
  )
}


const PDFView = (props) => {
  const numbers = Array.from({ length: 100 }, (_, i) => i + 1);
  const [selectedProject, setSelectedProject] = React.useState("Random Number Generator");
  const [acceptedPage, setAcceptedPage] = React.useState(false);


  return (
    <div style={{ display: "flex", height: "100vh" }}>
    {acceptedPage ? (
<>
<div style={{ width: "15%", backgroundColor: "#f0f0f0", color: "#000", borderTopRightRadius:"30px", borderBottomRightRadius:"30px" }}>
      <p style={{textAlign:"center", marginTop:"10px", letterSpacing:"2px", textTransform:"uppercase"}}>Digital Electronics Projects</p>
   
    <div>
      {DEProjects.map((project, index) => (
        <>
         <div style={{ height: "1px", backgroundColor: "#ccc", margin: "10px 0" }}></div>
     <button
          onClick={() => {
            setSelectedProject(project);
          }}
        
        style={{textAlign:"center", alignContent:"center", width:"100%", letterSpacing:"2px"}} key={index}>{project}</button>
     
        </>
      ))}
      

    </div>
      </div>
      <div style={{ width: "80%", display: "flex", justifyContent: "center", alignItems: "center" }}>
        <iframe
        
          src={`/DEProjects/${selectedProject}.pdf`}
          style={{ width: "100%", height: "95vh", borderRadius:"30px", marginLeft:"10px" }}>
        </iframe>
      </div>
</>


    ):(
<>
<div className="contact-container">
      <div className="contact-container1">
        <div className="contact-hero">
          <span className="contact-text de-text">Digital Electronics is a PLTW course that teaches the logic behind electronics</span>
          <h1 className="contact-text1">
          My Projects From Digital ELectronics
          </h1>
          <button onClick={() => {
           setAcceptedPage(true)
          }} className="contact-button button  de-button">View Projects</button>
        </div>
      </div>
    </div>
</>


    )}
    
    </div>
  )
}

const Contact = (props) => {
  return (
    <div className="contact-container">
      <div className="contact-container1">
        <div className="contact-hero">
          <span className="contact-text">For More Info and Portfolio</span>
          <h1 className="contact-text1">
            Interested in reaching out and talking to me?
          </h1>
          <button onClick={() => {
            window.location.href = "mailto:masoodyusuf78601@gmail.com"
          }} className="contact-button button">Contact Me</button>
        </div>
      </div>
    </div>
  )
}
const AboutMe = () => {
  return (
    <div className="about-me-container1">
          <h1 className="about-me-text">About Me</h1>
          <div className="skills-separator"></div>
          <span className="about-me-text01">
            <span>
              I have always had an interest in technology from as young as I can
              remember. The start of the COVID-19 pandemic started the pursuit
              of my technological interests, leading me to a career in
              technology.
            </span>
            <br className="about-me-text03"></br>
            <br></br>
            <span>
              Because of my childish mind, I began my journey by mastering Lua,
              the primary programming language for creating games on the Roblox
              platform. I deeply enjoyed this platform, and so I was able to
              easily master this language, create many games, and eventually
              hire my own developer team.
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
            <br className="about-me-text06"></br>
            <br></br>
            <span>
              Eventually, my curiosity led me to explore how websites
              functioned. I began by learning HTML, CSS, and JavaScript, the
              three essential web development languages. I was able to easily
              master these and I was able to create aesthetically pleasing and
              functional websites.
            </span>
            <br className="about-me-text09"></br>
            <br></br>
            <span>
              Building on my web development skills, I expanded my knowledge to
              include desktop and mobile app development, which shows my ability
              to adapt to multiple platforms, and continue learning.
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
            <br className="about-me-text12"></br>
            <br></br>
            <span>
              In my quest to gain a holistic understanding of software
              development, I shifted my focus to backend development. I worked
              on projects such as developing JavaScript chat bots, which had a
              virtual economy where users could trade with others. This
              experience provided me a deep understanding of server
              functionality and the creation of backends for front-end websites.
              Very recently, I was hired to design an app for a community in
              which I utilized my backend skills to host a database for all the
              users, as well as store important data that was needed for my
              client.
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
            <br className="about-me-text15"></br>
            <br></br>
            <span>
              Eager to learn about artificial intelligence, I embarked on
              learning Python. This mastery allowed me to interact with AI
              models and integrate them into various programs, such as
              constructing a virtual assistant for Windows.
            </span>
            <br className="about-me-text18"></br>
            <br></br>
            <span>
              To strengthen my skills, I learned C# programming, which I used
              for security testing via injection in my Roblox games. Also, I
              took AP Computer Science where I honed my skills in Java,
              broadening my knowledge in object-oriented programming.
            </span>
            <br className="about-me-text21"></br>
            <br></br>
            <br></br>
          </span>
        </div>

  )
}
const Skills = (props) => {
  return (
    <div className="skills-container">
      <div className="skills-container1">
        <div className="skills-features">
          <h1 className="skills-text">Skills &amp; Experience</h1>
          <div className="skills-separator"></div>
          <div className="skills-container2">
            <div className="skills-container3">
              <FeatureCard4
              src={`https://cdn-icons-png.flaticon.com/512/5968/5968322.png`}
                title="Backend"
                description="I have made multiple web servers with node.js for services like backends for phone apps, a centralized database for a chat app, a server for a socket.io game, as well as a backend for a verification app."
                rootClassName="rootClassName1"
              ></FeatureCard4>
              <FeatureCard4
              src="https://www.svgrepo.com/show/376344/python.svg"
                title="Machine Learning "
                description="I have created a few projects that involved the use of python. One of them was a windows voice assistant, that i trained to recognize my voice with certain phrases, to for example open an application on windows, change the volume, as well as automate tasks on websites."
                rootClassName="rootClassName4"
              ></FeatureCard4>
              <FeatureCard4
              src="https://upload.wikimedia.org/wikipedia/commons/thumb/5/58/Roblox_Studio_logo_2021_present.svg/1024px-Roblox_Studio_logo_2021_present.svg.png"
                title="Game Dev"
                description="I started my programming career developing games on ROBLOX Studio, which involved using the programming language Lua. This is where I learned object oriented programming, as well as my understanding of coding"
                rootClassName="rootClassName3"
              ></FeatureCard4>
              <FeatureCard4
              src="https://img.icons8.com/color/48/000000/react-native.png"
                title="Web &amp; Mobile Design"
                description="I enjoy using frameworks like React to create websites (like this one) as well as other frameworks like Next.js, bootstrap, and plain html. When designing mobile applications, I usually use React Native along with a framework such as Native Base, to make my designs responsive."
                rootClassName="rootClassName2"
              ></FeatureCard4>
            </div>
            <div className="skills-container4">
              <FeatureCard4
                src="https://upload.wikimedia.org/wikipedia/commons/thumb/9/91/Electron_Software_Framework_Logo.svg/2048px-Electron_Software_Framework_Logo.svg.png"
                title="Desktop Apps"
                description="I initially created applications for windows with C#, but then soon moved to using electron / tauri which essentially created a chrome tab that allowed html files to be run inside of them with desktop api."
                rootClassName="rootClassName5"
              ></FeatureCard4>
              <FeatureCard4
                src="https://javatutorial.net/wp-content/uploads/2014/10/java-logo.png"
                title="School"
                description="I have not used Java much outside of school, but some of the projects I have created as assignments in class are games like Poker, Hangman, etc. I have not found much of a use yet for Java, but look forward to using it in the future. "
                rootClassName="rootClassName6"
              ></FeatureCard4>
              <FeatureCard4
                className="miscCard"
                src="/misc.png"
                title="Misc"
                description="In my free time, I enjoy editing videos on Premiere Pro / Davinci Resolve, as well as creating graphics and photos on Photoshop / Illustrator. I also have experience with 3D modeling on Blender, and have created a few models for games. During the school year, I teach a few classes on coding to middle schoolers, and have created a few projects for them to work on. My current projects involve creating an application for my local community, which will allow them to host events and communicate with each other."
                rootClassName="rootClassName8"
              ></FeatureCard4>
            </div>
        
          </div>
        </div>
      </div>
    </div>
  )
}
const HeroPage = ({setShowChat}) => {
  const [currentHero, setCurrentHero] = React.useState("web");

  const handleHeroClick = () => {
    if (currentHero === "web") {
      setCurrentHero("software");
    } else if (currentHero === "software") {
      setCurrentHero("game");
    } else if (currentHero === "game") {
      setCurrentHero("web");
    }
  };

  return (
    <div className="hero-container">
      <div className="hero-container1">
        <div display="flex" flex-wrap="no-wrap" className="hero-testimonial">
          <div className="hero-container2">
          <Popover  className="dark" backdrop="opaque"  showArrow={true} placement="bottom">
      <PopoverTrigger>
      
        <motion.span   animate={{rotateZ: 6}} transition={{duration: 2, delay: 2, repeat: Infinity, repeatType: "mirror"}} className="hero-text">I&apos;m a</motion.span>
     
    
      </PopoverTrigger>
      <PopoverContent>
        <div >
          
        <h1 style={{textAlign:"center", fontSize:"2.5vh", color: "white", marginTop: "0.5vh"}}>About</h1>
          <br/>
          <Button style={{marginBottom:"0.5vh"}} auto shadow color="primary" size="small" onClick={() => {setShowChat(true)}}>Click to learn more about me</Button>
        </div>
      </PopoverContent>
    </Popover>
        
            <div className="hero-container3">
              <AnimatePresence>
              {currentHero === "web" && ( 
              
                   <motion.span
                 initial={{ opacity: 0 }}
                  exit={{ opacity: 0, y: -50 }}
                  animate={{ opacity: 1 }}
                  whileTap={{ scale: 0.8 }}
                  whileHover={({ scale: 1.2 })}
                  tramsition={{ duration: 3 }}
                key={"web"}
                className={`hero-text1`}
                onClick={handleHeroClick}
              >
                Web
              </motion.span>
            
                
              )}
            {currentHero === "software" && (

<motion.span
 initial={{ opacity: 0 }}
 exit={{ opacity: 0, y: -50 }}
 animate={{ opacity: 1 }}
 whileTap={{ scale: 0.8 }}
 whileHover={({ scale: 1.2 })}
 tramsition={{ duration: 3 }}
key={"software"}
className={`hero-text2`}
onClick={handleHeroClick}
>
Software
</motion.span>
            )}
             {currentHero === "game" && (
               <motion.span
               initial={{ opacity: 0 }}
               exit={{ opacity: 0, y: -50 }}
               animate={{ opacity: 1 }}
               whileTap={{ scale: 0.8 }}
               whileHover={({ scale: 1.2 })}
        
               tramsition={{ duration: 3 }}
               key={"game"}
               className={`hero-text3`}
               onClick={handleHeroClick}
             >
               Game
             </motion.span>
             )}
              </AnimatePresence>
             
             
            </div>
            <span className="hero-text4">Dev</span>
          </div>
          
        </div>
      </div>
    </div>
  );
};
const SecondPage = ({ isScrolled }) => {
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        padding: "var(--dl-space-space-threeunits)",
        maxWidth: "100vw",
        alignItems: "center",
        flexDirection: "column",
        justifyContent: "space-between",
        height: "110vh",
        position: "relative",
      }}
      className="page"
    >
      <img
        alt="image"
        src="/memojiface.png"
        style={{
          width: "544px",
          height: "470px",
          objectFit: "cover",
          marginTop: "20vh",
          borderRadius: "var(--dl-radius-radius-round)",
        }}
      />
      <span
        style={{
          maxWidth: "80vw",
          textAlign: "center",
          marginBottom: "0vh",
          fontStyle: "normal",
          fontWeight: "100",
          fontSize: "9vh",
          color: "rgb(255, 255, 255)",
        }}
      >
        <span>SECOND PAGE</span>
        <br></br>
      </span>
      <span
        style={{
          maxWidth: "600px",
          textAlign: "center",
          fontStyle: "normal",
          fontWeight: "100",
          fontSize: "4vh",
          color: "rgb(47, 47, 47)",
          alignSelf: "center",
          marginBottom: "20vh",
        }}
      >
        <span>MCDONALD WORKER</span>
        <button onClick={() => {}}>Chat With Me</button>
        <br></br>
      </span>
    </div>
  );
};
let debounce = false;
export default function App() {
  const [messages, setMessages] = React.useState([]);
  const [isScrolled, setIsScrolled] = React.useState(false);

  const [introAnim, setIntroAnim] = React.useState(false);
  const { scrollY } = useViewportScroll();
  const [showChat, setShowChat] = React.useState(false);

  const [canClick, setCanClick] = React.useState(true);

  const [rotation, setRotation] = React.useState({ x: 0, y: 0 });




  function addMsg(c, i) {
    setTimeout(() => {
      addMessage(c.replyOptions[i][0], "optionMsg", c.replyOptions[i][1]);
    }, 1000 * (i + 1));
  }
  function continueDialog(dialogueType) {
    const c = conversation[dialogueType];
    if (c) {
      addMessage(c.message, "receive");
      const replyOptions = c.replyOptions;
      setCanClick(false);
      for (let j = 0; j < replyOptions.length; j++) {
        addMsg(c, j);
      }
      setTimeout(() => {
        setCanClick(true);
      }
      , 1000 * (replyOptions.length + 1));
    }
  }
  function addMessage(text, type, typeInfo) {
    const newMessage = { text, type, typeInfo };
    setMessages((prevMessages) => [...prevMessages, { text, type, typeInfo }]);

    if (type == "receive") {
    }
 
  }
  function clearOptions() {
 
    var msgs = messages;
    if (msgs.length > 7) {
      setMessages([])
    } else {
      for (var i = 0; i < msgs.length; i++) {
        if (msgs[i].type == "optionMsg") {
          msgs.splice(i, 1);
          i--;
        }
      }
      setMessages(msgs);
      }
  
   
  }

  React.useEffect(() => {
    continueDialog("intro");

  }, []);
  const ref = React.useRef(null);
  React.useEffect(() => {
    if (messages.length) {
      ref.current?.scrollIntoView({ behavior: "smooth", block: "end"});
    }  
  }, [messages]);
  return (
    <AnimatePresence>
      {!introAnim && (
        <motion.div
          initial={{ opacity: 0, scale: 0 }}
          exit={{ opacity: 0, scale: 0 }}
          key="modal2"
          animate={{ opacity: 1, scale: [1] }}
          transition={{ duration: 2 }}
          style={{
            width: "100%",
            height: "100vh",

            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
          className={isScrolled ? "page" : "page"}
        >
          <FirstPage setIntroAnim={setIntroAnim} />
        </motion.div>
      )}
      {introAnim && (
        <div  id="main" className="scroll-container">
          <AnimatePresence>
            <section className="section1">
              {!showChat && (
                <HeroPage setShowChat={setShowChat} />
              )}
              {showChat && (
             
   <div className="msg-holder" style={stylesMsg.body}>
                   {messages.map((message, index) => (
                     <p
                       onClick={() => {
                        if (canClick == false) return
                         if (message.type == "optionMsg") {
                           clearOptions();
                           addMessage(message.text, "send");
                           if (message.typeInfo == "backMenu") {
                            setShowChat(false);
                           }
                           if (message.typeInfo == "back") {
                             continueDialog("intro");
                           } else {
                             console.log("typeInfo is", message.typeInfo);
                             continueDialog(message.typeInfo);
                           }
                         }
                       }}
                       key={index}
                       className={`${message.type} msgBubble`}
                       style={{ ...stylesMsg.p, ...stylesMsg[message.type] }}
                     >
                       {message.text}
                     </p>
                   ))}
                   <div ref={ref} />

                 </div>
          
                
              )}
            </section>

            <section className="section2">
              <AboutMe/>
            </section>
            <section className="section3">
             <Skills/>
            </section>
            <section className="section4">
              <References/>
            </section>
            <section className="section5">
            <PDFView/>
             
            </section>
            <section className="section6">
            <Contact/>
            </section>
          </AnimatePresence>
        </div>
      )}
    </AnimatePresence>
  );
}
